import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import GA4 from 'utils/GA4'

import Hero from 'components/Hero'
import Layout from 'components/Layout'
import VillageOpenHours from 'components/OpenHours/VillageOpenHours'

import StacklaWidget from 'components/StacklaWidget'
import renderContent from 'utils/componentRenderer'
import {
  filterRepeatedContent,
  groupContentTypes,
  NON_REPEATED_CONTENT_TYPES_T02,
} from 'utils/contentUtils'
import { Spacing50 } from 'styles/sharedStyle'

const SectionLandingPage = (props) => {
  const {
    data,
    pageContext: { pageLevel },
  } = props
  const {
    page,
    page: {
      hero,
      content,
      village,
      stacklaCarousel,
      jsonLdSchema,
      pageType,
      slug,
    },
    openingHoursExceptions,
    openingHoursLabels,
  } = data

  const media = hero && hero.media && hero.media[0]
  const hasHeroImage =
    (media && media.portrait && media.landscape) ||
    (media &&
      media.videoPlaceholder &&
      media.videoPlaceholder.landscape &&
      media.videoPlaceholder.portrait)
  const heroImage = hasHeroImage
    ? {
        portrait: media.portrait || media.videoPlaceholder.portrait,
        landscape: media.landscape || media.videoPlaceholder.landscape,
        altText:
          media.altText ||
          (media.videoPlaceholder ? media.videoPlaceholder.altText : ''),
      }
    : {}
  const hasHeroVideo = media && media.videoPortrait && media.videoLandscape
  const heroVideo = hasHeroVideo
    ? {
        portrait: media.videoPortrait,
        landscape: media.videoLandscape,
        opacity: media.opacity,
      }
    : {}
  const heroProps = hero
    ? {
        image: heroImage,
        eyebrow: hero.eyebrow,
        content: hero.headline,
        logo: page.brandLogo,
        video: heroVideo,
        villageSlug:
          pageLevel === 'collection'
            ? 'The Bicester Collection'
            : village?.name,
      }
    : null

  const contentToRender =
    !!content &&
    groupContentTypes(
      filterRepeatedContent(content, NON_REPEATED_CONTENT_TYPES_T02)
    )

  if (pageType.pageTypeSlug === 'offers' && !slug) {
    GA4('viewed_offers', { village_name: village?.name })
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      {heroProps && <Hero {...heroProps} />}

      {!!village && !!village.openingHours && (
        // @ts-ignore
        <VillageOpenHours
          background="White"
          village={village}
          openingHoursLabels={openingHoursLabels}
          openingHoursExceptions={openingHoursExceptions}
        />
      )}

      <Spacing50 />

      {renderContent(contentToRender, props)}

      <Spacing50 />

      {!!stacklaCarousel && !!stacklaCarousel.code && (
        <StacklaWidget
          code={stacklaCarousel.code.code}
          eyebrow={stacklaCarousel.eyebrow}
          headline={stacklaCarousel.headline}
          ctaLabel={stacklaCarousel.ctaLabel}
          ctaUrl={stacklaCarousel.ctaUrl}
        />
      )}
    </Layout>
  )
}

SectionLandingPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  pageContext: PropTypes.shape({
    pageLevel: PropTypes.string.isRequired,
  }).isRequired,
}

export const sectionLandingPageQuery = graphql`
  query(
    $id: String!
    $openingHoursId: String
    #  $dateNow: Date!
    $dateStart: Date!
    $dateEnd: Date!
    $nodeLocale: String!
    $collectionId: String
    $vrId: String
    $pageTypeSlug: String
    $villageSlug: String
    $needContactIcons: Boolean = false
  ) {
    page: contentfulPageTemplateSectionLandingT02(id: { eq: $id }) {
      locale: node_locale
      pageType {
        pageTypeSlug: slug
      }
      slug
      jsonLdSchema {
        jsonLdSchema
      }
      village {
        name
        villageSlug: slug
        currency
        home: page_template_home_t01 {
          ...headerFooterVillage
          locale: node_locale
        }
        villagePlaceholderImage: image {
          __typename
          ... on Node {
            ... on ContentfulEntityImageEnt02 {
              ...ResponsiveImageQuery
            }
          }
        }
        openingHours {
          ...villageOpeningHours
        }
        labels: label_brand_details_lab01 {
          readMoreLabel
          readLessLabel
          contactDetailsHeader
          ...virtualShoppingIconsAndLabelsQuery
          onlineLabel
          viewOnMapLabel
        }
        defaultLocale
        openingStatusLabel
      }
      hero {
        title
        headline
        eyebrow
        ...heroMediaQuery
      }
      content {
        __typename
        ... on Node {
          ...multipleComponentsT02
        }
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
      stacklaCarousel {
        code {
          code
        }
        eyebrow
        headline
        ctaLabel
        ctaUrl
      }
    }

    openingHoursExceptions: allContentfulCompOpeningHoursExceptionOph04(
      filter: {
        date: { lte: $dateEnd, gte: $dateStart }
        comp_opening_hours_village_oph01: {
          elemMatch: { id: { eq: $openingHoursId } }
        }
      }
    ) {
      exceptions: edges {
        node {
          date(formatString: "DD-MM-YYYY")
          openingTime
          closingTime
          closedFlag
          label
        }
      }
    }

    openingHoursLabels: allContentfulLabelOpeningHoursLab04(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          ...contentfulOpeningHoursLabels
        }
      }
    }

    collectionPage: contentfulPageTemplateHomeT01(id: { eq: $collectionId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }

    valueRetailPage: contentfulPageTemplateHomeT01(id: { eq: $vrId }) {
      header {
        ...header
      }
      footer {
        ...footer
      }
    }

    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          eventMoreInfoLabel
        }
      }
    }

    villages: allContentfulEntityVillageEnt01(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      nodes {
        name
        slug
        externalVillageLink
        node_locale
        city
        openingStatusLabel
        cardImage {
          portrait {
            file {
              url
            }
          }
        }
        homePages: page_template_home_t01 {
          defaultLocale
          header {
            languageGroup {
              languages {
                locale
              }
            }
          }
        }
      }
    }

    #  featuredOffers: allContentfulEntityOfferEnt05(
    #    filter: {
    #      node_locale: { eq: $nodeLocale }
    #      offerRunFinishDate: { gte: $dateNow }
    #    }
    #    sort: { fields: offerRunFinishDate }
    #  ) {
    #    nodes {
    #      id: contentful_id
    #      location {
    #        locationId: contentful_id
    #      }
    #      eyebrow
    #      headline
    #      description {
    #        description
    #      }
    #      offerDetailUrl
    #      offerExpirationLabel
    #      offerRunStartDate(formatString: "D MMMM")
    #      offerRunFinishDate(formatString: "D MMMM YYYY")
    #      offerImage {
    #        ...heroMediaQuery
    #      }
    #    }
    #  }

    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }

    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }

    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
    allMembershipPopUps: allContentfulCompMembershipPopUp(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
        active: { eq: true }
      }
    ) {
      totalCount
      edges {
        node {
          ...membershipPopUp
        }
      }
    }
  }
`

export default SectionLandingPage
